// import Burger from "./Burger";
import Logo from "./Logo";
import Menu from "./Menu";

function NavBar() {
  return (
    <div id="navbar" className="navbar">
      <Logo />
      <Menu />
    </div>
  );
}

export default NavBar;
