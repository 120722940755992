import React from 'react'

function Services() {
  return (
    <section className="services">
        <span className="ancres" id="service"></span>
        <h2 className="services__title">MY SERVICE</h2>
        <div className="services__content">
          <div className="service__item">
            <div className="service__item__container">
            <div className="service__item__icon">
            <i className="fa-solid fa-code" aria-hidden="true"></i>
            </div>
            <h3 className='service__item__title'>Web devlopment</h3>
            <p className="service__item__description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam unde accusantium perspiciatis doloribus, cupiditate nesciunt molestiae nisi, et explicabo consequuntur tenetur quaerat dolore fugit impedit, harum esse. Provident, officiis culpa?</p>
            </div>
          </div>
          <div className="service__item">
            <div className="service__item__container">
            <div className="service__item__icon">
            <i className="fa-solid fa-sliders" aria-hidden="true"></i>
            </div>
            <h3 className='service__item__title'>SEO Optimization</h3>
            <p className="service__item__description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam unde accusantium perspiciatis doloribus, cupiditate nesciunt molestiae nisi, et explicabo consequuntur tenetur quaerat dolore fugit impedit, harum esse. Provident, officiis culpa?</p>
            </div>
          </div>
          <div className="service__item">
            <div className="service__item__container">
            <div className="service__item__icon">
            <i className="fa-solid fa-network-wired" aria-hidden="true"></i>
            </div>
            <h3 className='service__item__title'>Network</h3>
            <p className="service__item__description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam unde accusantium perspiciatis doloribus, cupiditate nesciunt molestiae nisi, et explicabo consequuntur tenetur quaerat dolore fugit impedit, harum esse. Provident, officiis culpa?</p>
            </div>
          </div>
          <div className="service__item">
            <div className="service__item__container">
            <div className="service__item__icon">
            <i className="fa-solid fa-graduation-cap" aria-hidden="true"></i>
            </div>
            <h3 className='service__item__title'>Formation</h3>
            <p className="service__item__description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam unde accusantium perspiciatis doloribus, cupiditate nesciunt molestiae nisi, et explicabo consequuntur tenetur quaerat dolore fugit impedit, harum esse. Provident, officiis culpa?</p>
            </div>
          </div>
          <div className="service__item">
            <div className="service__item__container">
            <div className="service__item__icon">
            <i className="fa-solid fa-bug-slash" aria-hidden="true"></i>
            </div>
            <h3 className='service__item__title'>Debug</h3>
            <p className="service__item__description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam unde accusantium perspiciatis doloribus, cupiditate nesciunt molestiae nisi, et explicabo consequuntur tenetur quaerat dolore fugit impedit, harum esse. Provident, officiis culpa?</p>
            </div>
          </div>
          <div className="service__item">
            <div className="service__item__container">
            <div className="service__item__icon">
            <i className="fa-solid fa-paintbrush" aria-hidden="true"></i>
            </div>
            <h3 className='service__item__title'>Design</h3>
            <p className="service__item__description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam unde accusantium perspiciatis doloribus, cupiditate nesciunt molestiae nisi, et explicabo consequuntur tenetur quaerat dolore fugit impedit, harum esse. Provident, officiis culpa?</p>
            </div>
          </div>
        </div>
    </section>
  )
}

export default Services